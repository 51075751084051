import { useEffect, useRef } from 'react';

export const useEffectOnce = (callback, when) => {
    const hasRunOnce = useRef(false);
    useEffect(() => {
        if (when && !hasRunOnce.current) {
            callback();
            hasRunOnce.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [when]);
};
