import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

export const joinConnectionFromDeviceAPI = async (orgPortal) => {
    return await axios
        .post(ENDPOINT_DEV + `organization/${orgPortal.orgId}/session/${orgPortal.sessionId}/join-from-device`, {})
        .then((res) => {
            return res;
        });
};
