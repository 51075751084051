import cogoToast from 'cogo-toast';
import CustomToast from '../components/custom-toast';
import { ToastSuccessIcon, ToastErrorIcon } from '../assets/icons/toast';

export const TOAST_SUCCESS = 'success';
export const TOAST_FAILED = 'failed';
export const TOAST_ERROR = 'error';

export const showToast = (message, type = TOAST_SUCCESS) => {
    const { hide } = cogoToast.success(<CustomToast msg={message} type={type} />, {
        position: 'top-center',
        hideAfter: 5,
        renderIcon: () => {},
        onClick: () => hide(),
    });
};

export const getToastIcon = (type) => {
    switch (type) {
        case TOAST_SUCCESS:
            return <ToastSuccessIcon />;
        case TOAST_ERROR:
            return <ToastErrorIcon />;
        default:
            return <ToastSuccessIcon />;
    }
};
