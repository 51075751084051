import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from './redux-toolkit/store/configure';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { AblyClientProvider } from './Provider/AblyClientProvider';

// import { onServiceWorkerUpdate } from './utils/ServiceWorkerUpdater';
import App from './App';

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: 'portals-controller-app@' + process.env.REACT_APP_VERSION,
    integrations: [
        new Integrations.BrowserTracing(),
        // This will give ability to see correct path in the dev tools
        new Sentry.Integrations.Breadcrumbs({
            console: false,
        }),
    ],
    tracesSampleRate: 1.0,
});

const container = document.getElementById('root');

// create root element
const root = ReactDOM.createRoot(container);

root.render(
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
            <AblyClientProvider>
                <App />
            </AblyClientProvider>
        </PersistGate>
    </Provider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();
// serviceWorkerRegistration.register({
//   onUpdate: onServiceWorkerUpdate,
// });

/* import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import LogRocket from "logrocket";
import store from "./redux/store";
import { Provider } from "react-redux";
import ErrorBoundary from "./components/error-boundary";
import Authentication from "./components/authentication";
import SnackbarProvider from "./components/snackbar-provider";
import "./scss/style.scss";
// LogRocket.init("gail29/fp-client");

const App = () => {
  return (
    <ErrorBoundary>
      <Provider store={store}>
        <SnackbarProvider>
          <Authentication />
        </SnackbarProvider>
      </Provider>
    </ErrorBoundary>
  );
};

ReactDOM.render(<App />, document.getElementById("root")); */
