import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getQRInfoFromTokenAPI } from '../../api/getQRInfoFromTokenAPI';

const initialState = { tokenObj: null, loading: false, error: false, errorObj: null, isRenderingFromDynamicUrl: false };
export const getQRInfoFromToken = createAsyncThunk('token/getToken', async (tokenParams) => {
    return await getQRInfoFromTokenAPI(tokenParams);
});
const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        setEmptyTokenObj(state, action) {
            state.tokenObj = null;
        },
        setRenderingFromDynamicUrl(state, action) {
            state.isRenderingFromDynamicUrl = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getQRInfoFromToken.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
                state.tokenObj = null;
            })
            .addCase(getQRInfoFromToken.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                if (action.payload?.data) {
                    state.tokenObj = action.payload.data;
                } else {
                    state.tokenObj = null;
                    state.error = true;
                }
            })
            .addCase(getQRInfoFromToken.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
            });
    },
});
export const { setEmptyTokenObj, setRenderingFromDynamicUrl } = tokenSlice.actions;
export default tokenSlice.reducer;
