import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pollInvitiation } from '../redux-toolkit/reducer/connectionReducer';

export default function useCmdInvitedToSession({ message }) {
    const dispatch = useDispatch();
    const portal = useSelector((state) => state?.portal?.portal);
    const handleInvitedToSession = async () => {
        await dispatch(pollInvitiation({ orgId: portal?.org_id, portalId: portal.portal_id }));
    };

    useEffect(() => {
        const handleCommand = async () => {
            const command = message?.data?.message || '';
            const commandData = message?.data?.data || {};
            if (command === 'invited-to-session' && commandData?.portal_id === portal.portal_id) {
                await handleInvitedToSession(commandData);
            }
        };

        handleCommand();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message, dispatch]);
}
