import { CONNECTION_STATE, PORTAL_STATE } from './constants';

export const isEmpty = (value) => {
    return (
        value == null ||
        (typeof value === 'object' && Object.keys(value).length === 0) ||
        (typeof value === 'string' && value.trim().length === 0)
    );
};

export const updatePortalStatus = (portals) => {
    return portals.map((portal) => {
        if (checkIsPortalBooked(portal)) {
            return { ...portal, connectionStatus: 'online' };
        }
        return portal;
    });
};

export const getFeatureFlagObj = ({ featureFlags }) => {
    const featureObject = {};
    // Check if featureFlags is not undefined before iterating
    if (featureFlags) {
        featureFlags.forEach((flag) => {
            featureObject[flag.feature] = flag.enabled;
        });
    }
    return featureObject;
};

export const checkIsPortalInCall = (portalRawData) => {
    return (
        portalRawData?.portalStatus === PORTAL_STATE.ACTIVE &&
        portalRawData?.connectionStatus === CONNECTION_STATE.IN_CONNECTION
    );
};

export const checkIsPortalOnline = (portalRawData) => {
    return (
        portalRawData?.portalStatus === PORTAL_STATE.ACTIVE &&
        portalRawData?.connectionStatus === CONNECTION_STATE.AVAILABLE
    );
};

export const checkIsPortalBooked = (portalRawData) => {
    return (
        portalRawData?.portalStatus === PORTAL_STATE.ACTIVE &&
        portalRawData?.connectionStatus === CONNECTION_STATE.BOOKED
    );
};

export const checkIsPortalOffline = (portalRawData) => {
    return (
        portalRawData?.portalStatus === PORTAL_STATE.ACTIVE &&
        portalRawData?.connectionStatus === CONNECTION_STATE.OFFLINE
    );
};

export const checkIsPortalDisconnected = (portalRawData) => {
    return portalRawData?.portalStatus === PORTAL_STATE.DISCONNECTED;
};

export const checkIsPortalPending = (portalRawData) => {
    return portalRawData?.portalStatus === PORTAL_STATE.PENDING;
};

export const checkIsPortalInMaintenance = (portalRawData) => {
    return portalRawData?.portalStatus === PORTAL_STATE.IN_MAINTENANCE;
};

export const checkIsPortalError = (portalRawData) => {
    return portalRawData?.portalStatus === PORTAL_STATE.ERROR;
};
