import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { getBookingByHashAPI } from '../../api/getBookingsByHashAPI';

const initialState = {
    guest: {
        email: null,
        hash: null,
    },
    isLoading: false,
    isInvited: false,
    bookings: [],
    error: null,
};

export const getBookingByHash = createAsyncThunk('guest/getBookingByHash', async (queryParams, { rejectWithValue }) => {
    try {
        return await getBookingByHashAPI(queryParams);
    } catch (err) {
        if (!err.response) {
            throw err;
        }
        return rejectWithValue(err.response.data);
    }
});

const guestSlice = createSlice({
    name: 'guest',
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(getBookingByHash.pending, (state) => {
                state.isLoading = true;
                state.error = null;
            })
            .addCase(getBookingByHash.fulfilled, (state, action) => {
                if (action.payload?.data) {
                    const { data } = action.payload;
                    const guest_info = {
                        email: data.email,
                        hash: data.hash,
                    };
                    state.bookings = data?.bookings || [];
                    state.guest = guest_info;

                    sessionStorage.setItem('guest_info', JSON.stringify(guest_info));
                }
                state.error = null;
                state.isLoading = false;
            })
            .addCase(getBookingByHash.rejected, (state, action) => {
                state.error = action.payload?.message;
                state.isLoading = false;
            });
    },
});

export default guestSlice.reducer;
