import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    bookingInformation: null,
    sessionInfo: null,
};

const localBookingSlice = createSlice({
    name: 'localBooking',
    initialState,
    reducers: {
        setBookingInformation: (state, action) => {
            state.bookingInformation = action.payload.bookingInformation;
        },
        addInfoToStoredBooking: (state, action) => {
            if (state.bookingInformation) {
                state.bookingInformation = { ...state.bookingInformation, ...action.payload };
            }
        },
        setSessionInfo: (state, action) => {
            state.sessionInfo = action.payload.sessionInfo;
        },
    },
});

export const { setBookingInformation, addInfoToStoredBooking, setSessionInfo } = localBookingSlice.actions;

export default localBookingSlice.reducer;
