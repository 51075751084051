import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

export const portalModeAPI = async ({ orgId, portalId, isPortalAwake }) => {
    if (portalId) {
        return await axios
            .put(ENDPOINT_DEV + `organization/${orgId}/portal/${portalId}/mode`, {
                isPortalAwake: isPortalAwake,
            })
            .then((res) => {
                return res;
            });
    }
};
