import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

const getOngoingSessionByOrgIdPortalIdAPI = async (orgId, portalId) => {
    return await axios
        .get(`${ENDPOINT_DEV}organization/${orgId}/portal/${portalId}/session/status/ongoing`)
        .then((res) => res);
};

export default getOngoingSessionByOrgIdPortalIdAPI;
