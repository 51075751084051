import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { fireCommandToInitiateOrJoinBookingSessionAPI } from '../../api/fireCommandToInitiateOrJoinBookingSessionAPI';
import { getBookingConnectionDetailsAPI } from '../../api/getBookingConnectionDetailsAPI';
import { scannedBookingQRConnectionCallAPI } from '../../api/scannedBookingQRConnectionCallAPI';
import { joinConnectionFromDeviceAPI } from '../../api/joinConnectionFromDeviceAPI';
import { checkEarlyJoinBookingSessionAPI } from '../../api/checkEarlyJoinBookingSessionAPI';
import { updatePortalStatus } from '../../utils';

const initialState = {
    loading: false,
    error: false,
    errorObj: {},
    sessionId: '',
    bookedConnection: {},
    initiateOrJoinConnectionLoading: false,
    initiateOrJoinConnectionError: false,
    initiateOrJoinConnectionErrorObj: {},
    initiateOrJoinConnection: {},
    establishedConnection: {},
    chkEarlyJoinLoading: false,
    chkEarlyJoinError: false,
    chkEarlyJoinErrorObj: {},
    chkEarlyJoin: {
        earlyJoinAllowed: false,
    },
    chkEarlyJoinRemoteBkLoading: false,
    chkEarlyJoinRemoteBkError: false,
    chkEarlyJoinRemoteBkErrorObj: {},
    chkEarlyJoinRemoteBk: {
        earlyJoinAllowed: false,
    },
    bookingPortal: {},
    remoteBooking: {},
};

export const getBookedConnectionDetails = createAsyncThunk(
    'bookedConnection/getBookedConnectionDetails',
    async (sessionObj) => {
        return getBookingConnectionDetailsAPI(sessionObj);
    },
);

export const updateBookedConnectionDetails = createAsyncThunk(
    'bookedConnection/getBookedConnectionDetails',
    async (sessionid, body) => {
        return await updateBookedConnectionDetails(sessionid, body);
    },
);

export const fireCommandToInitiateOrJoinBookingSession = createAsyncThunk(
    'bookedConnection/fireCommandToInitiateOrJoinBookingSession',
    async (obj) => {
        return fireCommandToInitiateOrJoinBookingSessionAPI(obj);
    },
);
export const scannedBookingQRConnectionCall = createAsyncThunk(
    'bookedConnection/scannedBookingQRConnectionCall',
    async (orgPortal) => {
        return await scannedBookingQRConnectionCallAPI(orgPortal);
    },
);
export const joinConnectionFromDevice = createAsyncThunk(
    'bookedConnection/joinConnectionFromDevice',
    async (orgPortal) => {
        return await joinConnectionFromDeviceAPI(orgPortal);
    },
);

export const checkEarlyJoinBookingSession = createAsyncThunk(
    'bookedConnection/checkEarlyJoinBookingSession',
    async (obj) => {
        return checkEarlyJoinBookingSessionAPI(obj);
    },
);

export const checkEarlyJoinRemoteBkSession = createAsyncThunk(
    'bookedConnection/checkEarlyJoinRemoteBkSession',
    async (obj) => {
        return checkEarlyJoinBookingSessionAPI(obj);
    },
);

const bookingConnectionSlice = createSlice({
    name: 'bookedConnection',
    initialState,
    reducers: {
        setBookedConnection: (state, action) => {
            state.bookedConnection = action.payload;
        },
        setInitiateOrJoinConnection: (state, action) => {
            state.initiateOrJoinConnection = action.payload;
        },
        setAllBookingObjsEmpty: (state, action) => {
            state.bookedConnection = action.payload;
            state.initiateOrJoinConnection = action.payload;
            state.establishedConnection = action.payload;
        },
        setBookingPortal: (state, action) => {
            state.bookingPortal = action.payload;
        },
        setRemoteBooking: (state, action) => {
            state.remoteBooking = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getBookedConnectionDetails.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
            })
            .addCase(getBookedConnectionDetails.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                state.errorObj = {};
                if (action.payload?.data) {
                    const bookingSessionId = JSON.parse(sessionStorage.getItem('bookingInformation'))?.sessionId;
                    state.bookedConnection = !('sessionId' in action.payload.data)
                        ? {
                              ...action.payload.data,
                              portals: updatePortalStatus(action?.payload?.data?.portals),
                              sessionId: bookingSessionId,
                          }
                        : action.payload.data;
                } else {
                    state.bookedConnection = {};
                    state.error = true;
                }
            })
            .addCase(getBookedConnectionDetails.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                if (action.error.message.includes('400')) {
                    state.errorObj = { message: 'Requested session is not available or is deleted.' };
                } else {
                    state.errorObj = action.error;
                }

                state.bookedConnection = {};
            })

            .addCase(fireCommandToInitiateOrJoinBookingSession.pending, (state) => {
                // action is inferred correctly here if using TS
                state.initiateOrJoinConnectionLoading = true;
                state.initiateOrJoinConnectionError = false;
                state.initiateOrJoinConnectionErrorObj = {};
            })
            .addCase(fireCommandToInitiateOrJoinBookingSession.fulfilled, (state, action) => {
                state.initiateOrJoinConnectionLoading = false;
                state.initiateOrJoinConnectionError = false;
                state.initiateOrJoinConnectionErrorObj = {};
                if (action.payload?.data) {
                    state.initiateOrJoinConnection = action.payload.data;
                } else {
                    state.initiateOrJoinConnection = {};
                    state.initiateOrJoinConnectionError = true;
                }
            })
            .addCase(fireCommandToInitiateOrJoinBookingSession.rejected, (state, action) => {
                state.initiateOrJoinConnectionLoading = false;
                state.initiateOrJoinConnectionError = true;
                state.initiateOrJoinConnectionErrorObj = action.error;
                state.initiateOrJoinConnection = {};
            })
            .addCase(scannedBookingQRConnectionCall.pending, (state) => {
                // action is inferred correctly here if using TS
                state.loading = true;
                state.error = false;
            })
            .addCase(scannedBookingQRConnectionCall.fulfilled, (state, action) => {
                state.loading = false;
                state.error = false;
                if (action.payload?.data) {
                    state.establishedConnection = action.payload.data;
                } else {
                    state.establishedConnection = null;
                    state.error = true;
                }
            })
            .addCase(scannedBookingQRConnectionCall.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
                state.establishedConnection = null;
            })
            .addCase(joinConnectionFromDevice.pending, (state) => {
                state.loading = true;
                state.error = false;
            })
            .addCase(joinConnectionFromDevice.fulfilled, (state, action) => {
                // state.error = false;
                if (action.payload?.data?.status?.includes('failed')) {
                    state.establishedConnection = null;
                    state.error = true;
                    state.errorObj = action.payload?.data || { message: 'Something went wrong!' };
                } else if (action.payload?.data) {
                    state.establishedConnection = action.payload.data;
                    state.error = false;
                } else {
                    state.establishedConnection = null;
                    state.error = true;
                    state.errorObj = action?.error || { message: 'Something went wrong!' };
                }
                state.loading = false;
            })
            .addCase(joinConnectionFromDevice.rejected, (state, action) => {
                state.loading = false;
                state.error = true;
                state.errorObj = action.error;
                state.establishedConnection = null;
            })

            .addCase(checkEarlyJoinBookingSession.pending, (state) => {
                state.chkEarlyJoinLoading = true;
                state.chkEarlyJoinError = false;
            })
            .addCase(checkEarlyJoinBookingSession.fulfilled, (state, action) => {
                state.chkEarlyJoinLoading = false;
                state.chkEarlyJoinError = false;
                if (
                    action.payload &&
                    action.payload.hasOwnProperty('data') &&
                    action.payload.data &&
                    action.payload.data.hasOwnProperty('earlyJoinAllowed') &&
                    state.chkEarlyJoin &&
                    state.chkEarlyJoin.hasOwnProperty('earlyJoinAllowed') &&
                    state.chkEarlyJoin.earlyJoinAllowed !== action.payload.data.earlyJoinAllowed
                ) {
                    state.chkEarlyJoin = action.payload.data;
                }
            })
            .addCase(checkEarlyJoinBookingSession.rejected, (state, action) => {
                state.chkEarlyJoinLoading = false;
                state.chkEarlyJoinError = true;
                state.chkEarlyJoinErrorObj = action.error;
                state.chkEarlyJoin = null;
            })

            .addCase(checkEarlyJoinRemoteBkSession.pending, (state) => {
                state.chkEarlyJoinRemoteBkLoading = true;
                state.chkEarlyJoinRemoteBkError = false;
            })
            .addCase(checkEarlyJoinRemoteBkSession.fulfilled, (state, action) => {
                state.chkEarlyJoinRemoteBkLoading = false;
                state.chkEarlyJoinRemoteBkError = false;
                if (
                    action.payload &&
                    action.payload.hasOwnProperty('data') &&
                    action.payload.data &&
                    action.payload.data.hasOwnProperty('earlyJoinAllowed') &&
                    state.chkEarlyJoinRemoteBk &&
                    state.chkEarlyJoinRemoteBk.hasOwnProperty('earlyJoinAllowed') &&
                    state.chkEarlyJoinRemoteBk.earlyJoinAllowed !== action.payload.data.earlyJoinAllowed
                ) {
                    state.chkEarlyJoinRemoteBk = action.payload.data;
                }
            })
            .addCase(checkEarlyJoinRemoteBkSession.rejected, (state, action) => {
                state.chkEarlyJoinRemoteBkLoading = false;
                state.chkEarlyJoinRemoteBkError = true;
                state.chkEarlyJoinRemoteBkErrorObj = action.error;
                state.chkEarlyJoinRemoteBk = null;
            });
    },
});
export const {
    setInitiateOrJoinConnection,
    setAllBookingObjsEmpty,
    setBookedConnection,
    setBookingPortal,
    setRemoteBooking,
} = bookingConnectionSlice.actions;
export default bookingConnectionSlice.reducer;
