import { useChannel } from 'ably/react';
import { useCallback, useEffect } from 'react';
import { useSelector } from 'react-redux';

export default function useAblyPublishChannel() {
    const { channel } = useChannel('portals');
    const messageInfo = useSelector((state) => state.ably?.messageData);

    // Function to publish a message
    const publishMessage = useCallback(
        (message, messageData) => {
            if (channel) {
                channel.publish(message, messageData);
            } else {
                // eslint-disable-next-line no-console
                console.warn('Channel not ready for publishing. Ensure subscription is complete.');
            }
        },
        [channel],
    );

    useEffect(() => {
        return () => {
            // eslint-disable-next-line no-console
            console.log('unsubscribing from channel');
            channel.unsubscribe();
        };
    }, [channel]);

    useEffect(() => {
        const { message, portalId, data } = messageInfo || {};
        if (message) {
            publishMessage(portalId, {
                message: message,
                data: data,
            });
        }
    }, [messageInfo, publishMessage]);
}
