import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

export const invitePortalToSessionAPI = async ({ orgId, sessionId, inviterPortalId, inviteePortalIds }) => {
    return await axios
        .post(ENDPOINT_DEV + `organization/${orgId}/session/${sessionId}/invite`, {
            inviterPortalId,
            inviteePortalIds,
        })
        .then((res) => {
            return res;
        });
};

export const declineInviteAPI = async ({ orgId, sessionId, portalId, autoDeclineInvite }) => {
    return await axios
        .post(ENDPOINT_DEV + `organization/${orgId}/session/${sessionId}/portal/${portalId}/invitation/decline`, {
            autoDeclineInvite,
        })
        .then((res) => {
            return res;
        });
};
