import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

export const authPinVerificationAPI = async (orgPortal, body) => {
    try {
        const response = await axios.post(
            ENDPOINT_DEV + `organization/${orgPortal?.orgId}/portal/${orgPortal?.portalId}/verify-auth-pin`,
            body,
        );
        return response;
    } catch (error) {
        if (error.response) {
            throw new Error(error.response.data.message || 'An error occurred');
        }
        throw new Error('An error occurred');
    }
};
