import axios from 'axios';
import { ENDPOINT_DEV } from './configs';
export const getListOfPortalsAPI = async (orgPortal) => {
    return await axios
        .get(
            ENDPOINT_DEV +
                `organization/${orgPortal.orgId}/portal` +
                (orgPortal.limit ? '?limit=' + orgPortal.limit : ''),
        )
        .then((res) => {
            return res;
        });
};
