import { useChannel } from 'ably/react';
import { useEffect, useState } from 'react';

export default function useAblyChannelHook() {
    const [message, setAblyMessage] = useState({});

    const { channel } =
        useChannel('portals', (message) => {
            setAblyMessage(message);
        }) || {};

    useEffect(() => {
        return () => {
            // eslint-disable-next-line no-console
            console.log('unsubscribing from channel');
            channel.unsubscribe();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { message };
}
