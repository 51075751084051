import axios from 'axios';
import { ENDPOINT_DEV } from './configs';

const checkSessionExtensionAPI = async (sessionId, orgId) => {
    return await axios
        .get(`${ENDPOINT_DEV}session/${sessionId}/extend/availability?org_id=${orgId}`)
        .then((res) => res);
};

export default checkSessionExtensionAPI;
