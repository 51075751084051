import React from 'react';
const Loader = (props) => {
    return (
        <div className="spinner-bg hide">
            <div
                className="spinner"
                style={{ display: props.hasOwnProperty('hideSpinner') && props?.hideSpinner ? 'none' : 'unset' }}
            ></div>
            {props?.header && <h3 className="spinner-header">{props?.header}</h3>}
            {props?.content && <div className="spinner-content b2">{props?.content}</div>}
        </div>
    );
};
export default Loader;
