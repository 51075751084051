import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { getBookingConnectionDetailsAPI } from '../../api/getBookingConnectionDetailsAPI';
import { getPortalByOrgIdPortalIdAPI } from '../../api/getPortalByOrgIdPortalIdAPI';
import { CONNECTION_ENDED, STATE_IDLE } from '../../utils/constants';

const initialState = {
    callObject: null,
    connectionState: STATE_IDLE,
    roomUrl: null,
    loading: false,
    roomError: null,
    connection: null,
    localDimensions: null,
    isIOSLandscape: false,
    isAnyPortalPortrait: false,
    portals: [],
};

export const getJoinedConnectionDetails = createAsyncThunk('room/getJoinedConnectionDetails', async (sessionObj) => {
    return await getBookingConnectionDetailsAPI(sessionObj);
});

export const getPortalById = createAsyncThunk('room/getPortalById', async (params) => {
    return await getPortalByOrgIdPortalIdAPI(params);
});

const roomSlice = createSlice({
    name: 'room',
    initialState,
    reducers: {
        setCallObject: (state, action) => {
            state.callObject = action.payload;
        },
        setConnectionState: (state, action) => {
            state.connectionState = action.payload;
        },
        setRoomUrl: (state, action) => {
            state.roomUrl = action.payload;
        },
        setRoomError: (state, action) => {
            state.roomError = action.payload;
        },
        setLocalDimensions: (state, action) => {
            state.localDimensions = action.payload;
        },
        setIsIOSLandscape: (state, action) => {
            state.isIOSLandscape = action.payload;
        },
        removePortal: (state, action) => {
            state.portals = state.portals.filter((portal) => portal.portal_id !== action.payload);
        },
        setIsAnyPortalPortrait: (state, action) => {
            state.isAnyPortalPortrait = action.payload;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(getJoinedConnectionDetails.pending, (state) => {
                state.loading = true;
                // state.roomError = null;
                state.connection = null;
            })
            .addCase(getJoinedConnectionDetails.fulfilled, (state, action) => {
                state.loading = false;
                if (action.payload?.data?.message) {
                    state.connection = null;
                    state.roomError = "The meeting you're trying to join does not exist!";
                } else if (action.payload?.data) {
                    state.connection = action.payload.data;
                    // to show meeting ended if duration + starttime + status(ended) is greater current time
                    if (
                        action.payload.data?.status === CONNECTION_ENDED &&
                        new Date(
                            action?.payload?.data?.startTime + action?.payload?.data?.duration * 60 * 1000,
                        ).getTime() < new Date()
                    ) {
                        state.roomError = 'Meeting has ended!';
                    }
                } else {
                    state.connection = null;
                    state.roomError = "The meeting you're trying to join does not exist!";
                }
            })
            .addCase(getJoinedConnectionDetails.rejected, (state) => {
                state.loading = false;
                state.roomError = "The meeting you're trying to join does not exists!";
                state.connection = null;
            })
            .addCase(getPortalById.fulfilled, (state, action) => {
                if (action.payload?.data?.portal_id) {
                    const filteredPortals = state.portals.filter(
                        ({ portal_id }) => portal_id !== action.payload.data?.portal_id,
                    );
                    state.portals = [...filteredPortals, action.payload.data];
                }
            });
    },
});

export const {
    setCallObject,
    setConnectionState,
    setRoomUrl,
    setRoomError,
    setLocalDimensions,
    setIsIOSLandscape,
    setIsAnyPortalPortrait,
    removePortal,
} = roomSlice.actions;
export default roomSlice.reducer;
